import { IcoArrowLeft, IcoImport } from "assets/icons";
import colors from "colors";
import {
	Button,
	DatePicker,
	Input,
	InputFile,
	Loading,
	Multiselect,
	Select,
	TextArea
} from "components";
import "./styles.scss";

import { useFormik } from "formik";
import { Covenant } from "models/covenant/Covenant";
import {
	ContractingTypeOptions,
	CovenantStatus,
	CovenantStatusOptions,
	CovenantTypeOptions,
	CreditorOptions,
	CreditorTypeOptions,
	CurrencyOptions
} from "models/covenant/enums";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { CovenantService } from "services/covenants";

import { useDispatch } from "react-redux";

import ModalCancelConfirm from "components/ModalCancelConfirm";
import ToastContent from "components/ToastContent";
import { ContractingCompany } from "models/covenant/ContractingCompany";
import { CovenantCreationEditionProps } from "models/covenant/types";
import { useNavigate, useParams } from "react-router-dom";
import { ContractingCompanyService } from "services/companies";
import { FileService } from "services/file";
import {
	ImportErrorDetailsMissingFields,
	ImportErrorItem,
	clear,
	setCovenantCreated,
	setCovenantEdited,
	setImportErrors
} from "store/features/covenant/slice";

export default function CovenantCreateEditPage() {
	const { id } = useParams();
	const isEdit = !!id;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const covenantService = new CovenantService();
	const contractingCompanyService = new ContractingCompanyService();
	const fileService = new FileService();
	const [initialValues, setInitialValues] =
		useState<CovenantCreationEditionProps>(Covenant.initialValues);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [hasErrorsOnSubmit, sethasErrorsOnSubmit] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [contractingCompanies, setContractingCompanies] = useState<
		ContractingCompany[]
	>([]);
	const [fieldsDisabled, setFieldsDisabled] = useState(true);
	const [InputFileName, setInputFileName] = useState("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const AZURE_PDF_CONTAINER_NAME = "covenantspdfs";

	const [loadingCovenant, setLoadingCovenant] = useState(false);

	async function handleEditCovenant(values: CovenantCreationEditionProps) {
		if (isEdit) {
			setLoadingCovenant(true);

			try {
				const resp = await covenantService.editCovenant(
					Covenant.toRequest(values),
					id
				);
				toast.dark(
					<ToastContent
						type="success"
						title="Contrato alterado"
						subtitle="As informações do contrato foram alteradas"
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				dispatch(setCovenantEdited(resp));
				navigate("/covenants");
			} catch (error) {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			} finally {
				setLoadingCovenant(false);
			}
		}
	}

	function submitCovenant(values: CovenantCreationEditionProps) {
		setIsLoading(true);
		setLoadingCovenant(true);
		covenantService
			.create("covenants", Covenant.toRequest(values))
			.then((resp) => {
				dispatch(setCovenantCreated(resp));
				setIsLoading(false);
				navigate("/covenants/create/success");
			})
			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			)
			.finally(() => {
				setIsLoading(false);
				setLoadingCovenant(false);
			});
	}

	const formik = useFormik({
		initialValues: Covenant.initialValues,
		validationSchema: Covenant.validationSchema,
		onSubmit: (values) => {
			if (values.covenantPdfFile) {
				fileService
					.uploadFile(values.covenantPdfFile, AZURE_PDF_CONTAINER_NAME)
					.then((pdfUrl: string) => {
						values.covenantPdf = pdfUrl;
						if (isEdit) {
							handleEditCovenant(values);
						} else {
							submitCovenant(values);
						}
					})
					.catch((error) => {
						toast.error(
							<ToastContent
								type="error"
								title="Erro"
								subtitleError={error}
								onClose={() => toast.dismiss()}
							/>
						);
					});
				return;
			}
			if (isEdit) {
				handleEditCovenant(values);
				return;
			}
			submitCovenant(values);
		}
	});

	const validateThenHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			sethasErrorsOnSubmit(true);
		} else {
			formik.handleSubmit();
		}
	};

	useEffect(() => {
		contractingCompanyService
			.listContractingCompanies()
			.then((resp) => {
				setContractingCompanies(resp);
			})
			.catch((error) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				);
			});
	}, []);

	useEffect(() => {
		if (!id) return;

		setLoadingCovenant(true);

		covenantService
			.retrieve(id)
			.then((covenant) => {
				if (covenant.status.code === CovenantStatus.Ativo.code) {
					setFieldsDisabled(false);
				}
				const newValues = {
					...formik.values,
					...covenant.asValues
				};

				setInitialValues({
					...newValues
				});
				formik.setValues(() => ({
					...newValues
				}));

				if (covenant.covenantPdf) {
					setInputFileName(covenant.covenantPdf);
				}
			})
			.catch((error) =>
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>
				)
			)
			.finally(() => {
				setLoadingCovenant(false);
			});
	}, [id]);

	const closeModal = () => {
		setIsCancelModalOpen(false);
		navigate("/covenants");
	};

	const confirmModalAction = () => {
		if (formik.isValid) {
			formik.submitForm();
			setIsCancelModalOpen(false);
			return true;
		}
		setIsCancelModalOpen(false);
		return false;
	};

	const transformDetailsToErrors = (
		importErrorDetails: ImportErrorDetailsMissingFields
	): ImportErrorItem[] => {
		const errors: ImportErrorItem[] = [];

		Object.keys(importErrorDetails.details).forEach((type) => {
			if (Array.isArray(importErrorDetails.details[type])) {
				(importErrorDetails.details[type] as unknown as string[]).forEach(
					(field: string) => {
						errors.push({
							line: 1,
							type,
							field,
							error: "Cabeçalho inválido"
						});
					}
				);
			}
		});

		return errors;
	};

	const importExcel = (file: File) => {
		setLoadingCovenant(true);
		covenantService
			.importFromExcel(file)
			.then((resp) => {
				formik.setValues((values: any) => ({
					...values,
					...resp.asImportValues
				}));
				setLoadingCovenant(false);
				setFieldsDisabled(false);
				dispatch(clear());
			})
			.catch((errorResp) => {
				if ("errors" in errorResp.response.data) {
					if (
						errorResp.response.data.errors?.["Arquivo do Contrato"]?.[0]
							?.message === "Campos obrigatórios não foram enviados"
					) {
						const importErrorItems = transformDetailsToErrors(
							errorResp.response.data.errors["Arquivo do Contrato"][0]
						);

						errorResp.response.data.errors["Arquivo do Contrato"][0].details =
							importErrorItems;
					}
					dispatch(setImportErrors(errorResp.response.data.errors));
				} else {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={errorResp}
							onClose={() => toast.dismiss()}
						/>
					);
				}
				navigate("/covenants/create/error");
			});
	};

	const hasChanges = () =>
		JSON.stringify(formik.values) !== JSON.stringify(initialValues);

	return (
		<form className="covenant-creation-page">
			<div className="covenant-creation-header">
				<div className="flex--row flex-items--center">
					<Button
						kind="icon"
						styled="ghost"
						cssClass="left-arrow"
						onClick={() =>
							hasChanges() ? setIsCancelModalOpen(true) : navigate("/covenants")
						}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
					<span className="covenant-creation-title">
						{isEdit ? "Editar contrato" : "Criar contrato"}
					</span>
				</div>
				{!isEdit && (
					<Button
						kind="default"
						styled="secondary"
						size="small"
						cssClass="import-button"
						onClick={() => document.getElementById("excel-file-input")?.click()}
					>
						<IcoImport size="16" />
						<span className="padding--l-1">Importar Excel</span>
						<input
							id="excel-file-input"
							type="file"
							accept=".xlsx"
							style={{ display: "none" }}
							onChange={(e) => {
								if (e.target.files && e.target.files.length > 0) {
									importExcel(e.target.files[0]);
								}
							}}
						/>
					</Button>
				)}
			</div>
			{loadingCovenant ? (
				<div className="covenant-loading">
					<Loading />
				</div>
			) : (
				<div>
					<div className="covenant-creation-fields-group stack--6">
						<div className="covenant-creation-field-title">Contrato</div>
						<div className="covenant-creation-field-container">
							<Select
								id="covenantType"
								name="covenantType"
								label="Tipo de contrato"
								options={CovenantTypeOptions.sort((a, b) =>
									a.label.localeCompare(b.label)
								)}
								onChange={(selected: any) => {
									formik.setFieldValue("covenantType", selected.value);
								}}
								value={CovenantTypeOptions.find(
									(covenantType) =>
										covenantType.value === formik.values.covenantType
								)}
								placeholder="Selecione"
								isDisabled={fieldsDisabled}
								error={
									hasErrorsOnSubmit ? formik.errors.covenantType : undefined
								}
								className={
									hasErrorsOnSubmit && formik.errors.covenantType
										? "select-error"
										: ""
								}
							/>
							<Input
								label="Nº Contrato"
								placeholder="Digite"
								name="covenantNumber"
								value={formik.values.covenantNumber?.toUpperCase()}
								onChange={formik.handleChange}
								disabled={fieldsDisabled}
								error={
									hasErrorsOnSubmit ? formik.errors.covenantNumber : undefined
								}
								className={
									hasErrorsOnSubmit && formik.errors.covenantNumber
										? "input-error"
										: ""
								}
							/>
						</div>
						<div className="covenant-creation-field-container">
							<Select
								id="currency"
								name="currency"
								label="Moeda"
								options={CurrencyOptions}
								onChange={(selected: any) => {
									formik.setFieldValue("currency", selected.value);
								}}
								value={CurrencyOptions.find(
									(type) => type.value === formik.values.currency
								)}
								placeholder="Selecione"
								isDisabled={fieldsDisabled}
								error={hasErrorsOnSubmit ? formik.errors.currency : undefined}
								className={
									hasErrorsOnSubmit && formik.errors.currency
										? "select-error"
										: ""
								}
							/>
							<div className="input-container">
								<div className={fieldsDisabled ? "label disabled" : "label"}>
									Valor do contrato
								</div>
								<NumericFormat
									name="totalValue"
									className={`input-component ${
										hasErrorsOnSubmit && formik.errors.totalValue
											? "input-error"
											: ""
									}`}
									placeholder="Digite"
									value={formik.values.totalValue}
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale
									disabled={fieldsDisabled}
									prefix="R$ "
									onValueChange={(values: { value: string }) => {
										const { value } = values;
										formik.setFieldValue("totalValue", value);
									}}
								/>
								{hasErrorsOnSubmit && (
									<div className="error-message">
										{formik.errors.totalValue}
									</div>
								)}
							</div>
						</div>
						<div className="covenant-creation-field-container">
							<DatePicker
								label="Data da assinatura"
								name="signatureDate"
								value={formik.values.signatureDate}
								onBlur={formik.handleBlur}
								onChange={(date) => {
									formik.setFieldValue("signatureDate", date);
								}}
								disabled={fieldsDisabled}
								error={
									hasErrorsOnSubmit ? formik.errors.signatureDate : undefined
								}
								className={
									hasErrorsOnSubmit && formik.errors.signatureDate
										? "date-picker-error"
										: ""
								}
							/>
							<DatePicker
								label="Data de vencimento"
								name="expirationDate"
								onBlur={formik.handleBlur}
								onChange={(date) => {
									formik.setFieldValue("expirationDate", date);
								}}
								value={formik.values.expirationDate}
								disabled={fieldsDisabled}
								error={
									hasErrorsOnSubmit ? formik.errors.expirationDate : undefined
								}
								className={
									hasErrorsOnSubmit && formik.errors.expirationDate
										? "date-picker-error"
										: ""
								}
							/>
						</div>
						<div className="covenant-creation-field-container">
							<Select
								id="status"
								name="status"
								label="Status"
								options={CovenantStatusOptions}
								onChange={(selected: any) => {
									formik.setFieldValue("status", selected.value);
								}}
								value={CovenantStatusOptions.find(
									(type) => type.value === formik.values.status
								)}
								placeholder="Selecione"
								isDisabled={fieldsDisabled || isEdit}
								error={hasErrorsOnSubmit ? formik.errors.status : undefined}
								className={`status-field ${
									hasErrorsOnSubmit && formik.errors.status
										? "select-error"
										: ""
								}`}
							/>
						</div>
						<div className="covenant-creation-field-container description-field-container">
							<TextArea
								id="description"
								name="description"
								label="Descrição do contrato"
								placeholder="Digite"
								error={
									hasErrorsOnSubmit ? formik.errors.description : undefined
								}
								className={`description-field ${
									hasErrorsOnSubmit && formik.errors.description
										? "textarea-error"
										: ""
								}`}
								value={formik.values.description}
								onChange={(e) => {
									formik.handleChange(e);
									setCharCount(e.target.value.length);
								}}
								rows={5}
								maxLength={150}
								disabled={fieldsDisabled}
							/>
							<span
								className={
									fieldsDisabled
										? "character-count disabled"
										: "character-count"
								}
							>
								{charCount}/150 Caracteres
							</span>
						</div>
						<div className="covenant-creation-field-container">
							<InputFile
								id="covenant-file"
								name="covenantPdfFile"
								title={
									InputFileName !== ""
										? "Documento Anexado"
										: "Anexar documento"
								}
								description={InputFileName !== "" ? InputFileName : "PDF"}
								accept="application/pdf"
								file={formik.values.covenantPdfFile}
								isEditionPage={isEdit}
								onChange={(e: any) => {
									const selectedFile: File = e.target.files[0];
									if (!selectedFile) {
										return;
									}

									if (selectedFile.type === "application/pdf") {
										setInputFileName(selectedFile.name);
										formik.setFieldValue("covenantPdfFile", selectedFile);
									} else {
										toast.error(
											<ToastContent
												type="error"
												title="Erro"
												subtitle="O arquivo enviado não é do tipo PDF"
												onClose={() => toast.dismiss()}
											/>
										);
									}
								}}
								successMessage="Completo"
								onClickRemoveFile={() => {
									setInputFileName("");
									formik.setFieldValue("covenantPdfFile", undefined);
								}}
								onClickRetry={() => {}}
								error={
									hasErrorsOnSubmit
										? formik.errors.covenantPdf &&
										  "Para prosseguir, anexe o documento em formato PDF"
										: undefined
								}
								className={
									hasErrorsOnSubmit && formik.errors.covenantPdf
										? "input-file-error"
										: ""
								}
								disabled={fieldsDisabled}
							/>
						</div>
						<div className="covenant-creation-field-title">Contratante</div>
						<div className="covenant-creation-field-container">
							<Multiselect
								label="Empresas contratantes"
								id="contractingCompanies"
								name="contractingCompanies"
								options={contractingCompanies
									.sort((a, b) => a.name.localeCompare(b.name))
									.map((company) => ({
										value: company.id,
										label: company.name
									}))}
								onChange={(company) => {
									const selectedCompanies = (company as any[]).map(
										(comp) => comp.value
									);
									formik.setFieldValue(
										"contractingCompanies",
										selectedCompanies
									);
								}}
								value={contractingCompanies
									.filter((company) =>
										formik.values.contractingCompanies?.includes(company.id)
									)
									.map((company) => ({
										value: company.id,
										label: company.name
									}))}
								placeholder="Selecione"
								isDisabled={fieldsDisabled}
								error={
									hasErrorsOnSubmit
										? formik.errors.contractingCompanies
										: undefined
								}
								className={
									hasErrorsOnSubmit && formik.errors.contractingCompanies
										? "multiselect-error"
										: ""
								}
							/>
							<Select
								id="contractingType"
								name="contractingType"
								label="Tipo de contratante"
								options={ContractingTypeOptions}
								onChange={(selected: any) => {
									formik.setFieldValue("contractingType", selected.value);
								}}
								value={ContractingTypeOptions.find(
									(type) => type.value === formik.values.contractingType
								)}
								placeholder="Selecione"
								isDisabled={fieldsDisabled}
								className={
									hasErrorsOnSubmit && formik.errors.contractingType
										? "select-error"
										: ""
								}
								error={
									hasErrorsOnSubmit ? formik.errors.contractingType : undefined
								}
							/>
						</div>
						<div className="covenant-creation-field-title">Credor</div>
						<div className="covenant-creation-field-container">
							<Select
								id="creditor"
								name="creditor"
								label="Credor"
								options={CreditorOptions.sort((a, b) =>
									a.label.localeCompare(b.label)
								)}
								onChange={(selected: any) => {
									formik.setFieldValue("creditor", selected.value);
								}}
								value={CreditorOptions.find(
									(type) => type.value === formik.values.creditor
								)}
								placeholder="Selecione"
								isDisabled={fieldsDisabled}
								className={
									hasErrorsOnSubmit && formik.errors.creditor
										? "select-error"
										: ""
								}
								error={hasErrorsOnSubmit ? formik.errors.creditor : undefined}
							/>

							<Select
								id="creditorType"
								name="creditorType"
								label="Tipo de credor"
								options={CreditorTypeOptions}
								onChange={(selected: any) => {
									formik.setFieldValue("creditorType", selected.value);
								}}
								value={CreditorTypeOptions.find(
									(type) => type.value === formik.values.creditorType
								)}
								placeholder="Selecione"
								isDisabled={fieldsDisabled}
								className={
									hasErrorsOnSubmit && formik.errors.creditorType
										? "select-error"
										: ""
								}
								error={
									hasErrorsOnSubmit ? formik.errors.creditorType : undefined
								}
							/>
						</div>
					</div>
					<div className="buttons">
						<Button
							cssClass="cancel-button"
							kind="default"
							styled="secondary"
							type="button"
							size="medium"
							onClick={() =>
								hasChanges()
									? setIsCancelModalOpen(true)
									: navigate("/covenants")
							}
						>
							Cancelar
						</Button>
						<Button
							cssClass="submit-button"
							kind="default"
							styled="primary"
							type="button"
							size="medium"
							onClick={(e: any) => validateThenHandleSubmit(e)}
							disabled={fieldsDisabled || !formik.dirty}
							isLoading={isLoading}
						>
							Salvar contrato
						</Button>
					</div>
				</div>
			)}
			<ModalCancelConfirm
				modalTitle="Deseja sair sem salvar?"
				modalInfo="Ao sair sem salvar, suas ações serão perdidas"
				isOpen={isCancelModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				closeLabel="Sair sem salvar"
				confirmLabel="Salvar"
				toastSuccessTitle="Contrato editado"
				toastSuccessMessage="As informações do contrato foram alteradas"
				kind="warning"
			/>
		</form>
	);
}
